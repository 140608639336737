import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { admin_server } from "servers";
import toast from "react-hot-toast";
import { useState } from "react";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value); // Update the email state with the input value
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value); // Update the email state with the input value
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      const response = await fetch(`${admin_server}/login-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        credentials: "include",
        body: JSON.stringify({ email, password }),
      });
      const res = await response.json();
      if (res.success) {
        console.log("sssss");
        window.location.reload(true);
        toast.success("Welcome");
      } else {
        console.log(res);
        toast.error(res.message);
      }
    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  };
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">
      {/* Sign in section */}
      <form onSubmit={handleSubmit}>
        <div className="mt-[10vh] w-full max-w-full flex-col items-center xl:w-[420px]">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white text-center">
            Sign In
          </h4>
          <p className="mb-9 ml-1 text-base text-gray-600 text-center ">
            Enter your email and password to sign in!
          </p>

          {/* Email */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@simmmple.com"
            id="email"
            type="text"
            onChange={handleEmailChange}
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            onChange={handlePasswordChange}
          />
          {/* Checkbox */}
          <div className="mb-4 flex items-center justify-between px-2">
            <div className="flex items-center">
              <Checkbox />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                Keep me logged In
              </p>
            </div>
            <a
              className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              href=" "
            >
              Forgot Password?
            </a>
          </div>
          <button
            type="submit"
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Sign In
          </button>
        </div>
      </form>

    </div>
  );
}
