import ResultDisplay from "./components/ResultDisplay";
import { useEffect, useState } from "react";
import { getAllUserEntries } from "actions/user";

const UsersEntry = () => {
  const [entries, setEntries] = useState([]);
  const [currentEntryIndex, setCurrentEntryIndex] = useState(0);

  useEffect(() => {
    // Fetch entries from the server
    getAllUserEntries()
      .then((entries) => {
        setEntries(entries);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        // setLoading(false);
      });
  }, []);

  const handleNextEntry = () => {
    if (currentEntryIndex < entries.length - 1) {
      setCurrentEntryIndex(currentEntryIndex + 1);
    }
  };

  const handlePreviousEntry = () => {
    if (currentEntryIndex > 0) {
      setCurrentEntryIndex(currentEntryIndex - 1);
    }
  };

  const formatKey = (key) => {
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };
  const excludedKeys = ["updatedAt", "createdAt", "__v"];
  const currentEntry = entries[currentEntryIndex];

  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5">
      <div className="col-span-1 h-fit w-full">
        {entries.length > 0 ? (
          <div>
            <ResultDisplay
              entries={entries}
              currentEntryIndex={currentEntryIndex}
              currentEntry={currentEntry}
              formatKey={formatKey}
              excludedKeys={excludedKeys}
              handleNextEntry={handleNextEntry}
              handlePreviousEntry={handlePreviousEntry}
            />
          </div>
        ) : (
          <p className='dark:text-white'>No entries found</p>
        )}
        {/* NFt Header */}
        {/* <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
            Trending NFTs
          </h4>
          <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Art
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Music
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Collection
              </a>
            </li>
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                <a href=" ">Sports</a>
              </a>
            </li>
          </ul>
        </div> */}
      </div>

      {/* right side section */}

      {/* <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
        <TopCreatorTable
          extra="mb-5"
          tableData={tableDataTopCreators}
          columnsData={tableColumnsTopCreators}
        />
        <HistoryCard />
      </div> */}
    </div>
  );
};

export default UsersEntry;
