import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import DashboardEntriesReview from "views/test";
import ProtectedRoute from "middleware/ProtectedRoute";
import ProtectedAuthRoute from "middleware/ProtectedAuthRoute";
import { Toaster } from "react-hot-toast";

const App = () => {
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <>
      <ProtectedAuthRoute isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
        <Routes>
          <Route path="auth/*" element={<AuthLayout />} />
          <Route path="/" element={<Navigate to="/auth" replace />} />
        </Routes>
      </ProtectedAuthRoute>

      <ProtectedRoute isSuccess={isSuccess} setIsSuccess={setIsSuccess}>
        <Routes>
          <Route path="/" element={<Navigate to="/admin" replace />} />
          <Route path="admin/*" element={<AdminLayout />} />
          <Route path="/test" element={<DashboardEntriesReview />} />
        </Routes>
      </ProtectedRoute>
      <Toaster />
    </>
  );
};

export default App;
