import axios from "axios";
import { admin_server } from "servers";

export const logout = () => {
  return axios
    .get(`${admin_server}/logout`, {
      withCredentials: true,
    })
    .then((response) => {
      return {
        message: response.data.message,
      };
    })
    .catch((error) => {
      const axiosError = error;
      return {
        error: axiosError.message,
      };
    });
};
