import axios from "axios";
import { user_server } from "servers";

export const getAllUserEntries = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${user_server}/admin/get-wizard-entries`, { withCredentials: true })
      .then((response) => {
        const entries = response.data.wizardData;
        console.log(entries);
        resolve(entries);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};
