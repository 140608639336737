import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";

import TunersEntry from "views/admin/tunersentry";

// Auth Imports
// import SignIn from "views/auth/SignIn";

// Icon Imports
import { MdHome, MdEngineering } from "react-icons/md";
import UsersEntry from "views/admin/usersentry";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "home",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  {
    name: "Tuner Registration Entry",
    layout: "/admin",
    path: "tuner-entry",
    icon: <MdEngineering className="h-6 w-6" />,
    component: <TunersEntry />,
    secondary: true,
  },
  {
    name: "User Registration Entry",
    layout: "/admin",
    path: "user-entry",
    icon: <MdEngineering className="h-6 w-6" />,
    component: <UsersEntry />,
    secondary: true,
  },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignIn />,
  // },
];
export default routes;
