import { getAllWizardEntries } from "actions/tuners";
import React, { useState, useEffect } from "react";

const DashboardEntriesReview = () => {
  const [entries, setEntries] = useState([]);
  const [currentEntryIndex, setCurrentEntryIndex] = useState(0);

  useEffect(() => {
    // Fetch entries from the server
    getAllWizardEntries()
      .then((entries) => {
        setEntries(entries);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        // setLoading(false);
      });
  }, []);

  const handleNextEntry = () => {
    if (currentEntryIndex < entries.length - 1) {
      setCurrentEntryIndex(currentEntryIndex + 1);
    }
  };

  const handlePreviousEntry = () => {
    if (currentEntryIndex > 0) {
      setCurrentEntryIndex(currentEntryIndex - 1);
    }
  };

  const formatKey = (key) => {
    return key.replace(/_/g, " ").replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const currentEntry = entries[currentEntryIndex];

  return (
    <div>
      {entries.length > 0 ? (
        <div>
          <h2>Entry {currentEntryIndex + 1}</h2>
          {Object.entries(currentEntry).map(([key, value]) => {
            if (typeof value === "string" || typeof value === "number") {
              return (
                <p key={key}>
                  {formatKey(key)}: {value}
                </p>
              );
            }
            return null;
          })}
          {Object.entries(currentEntry).map(([key, value]) => {
            if (typeof value === "object" && value !== null && value.url) {
              return (
                <div key={key}>
                  <p>{formatKey(key)}:</p>
                  <img src={value.url} alt={key} />
                </div>
              );
            }
            return null;
          })}
          <button onClick={handlePreviousEntry} disabled={currentEntryIndex === 0}>
            Previous
          </button>
          <button onClick={handleNextEntry} disabled={currentEntryIndex === entries.length - 1}>
            Next
          </button>
        </div>
      ) : (
        <p>No entries found</p>
      )}
    </div>
  );
};

export default DashboardEntriesReview;
