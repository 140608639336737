import nft1 from "assets/img/nfts/NftBanner1.png";
import { useEffect, useState } from "react";
import { TbSquareRoundedLetterQ } from "react-icons/tb";

const ResultDisplay = ({
  entries,
  currentEntryIndex,
  currentEntry,
  formatKey,
  excludedKeys,
  handleNextEntry,
  handlePreviousEntry,
}) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (entries.length > 0) {
      const currentEntry = entries[currentEntryIndex];
      setUser(currentEntry);
    }
  }, [entries, currentEntryIndex]);
  const numberOfUsersEntry = entries ? entries.length : 0;
  return (
    <div
      className="flex w-full flex-col rounded-[20px] bg-white bg-cover px-[30px] py-[30px] md:px-[64px] md:py-[56px]"
      // style={{ backgroundImage: `url(${nft1})` }/}
    >
      <div className="flex justify-between">
        <button
          className="linear rounded-xl bg-blue-700 px-4 py-1 text-center text-base font-medium text-white transition duration-200 hover:!bg-blue-500 active:!bg-white/70"
          onClick={handlePreviousEntry}
          disabled={currentEntryIndex === 0}
        >
          Previous
        </button>
        <p>Entry {currentEntryIndex + 1} of {numberOfUsersEntry}</p>
        <button
          className="linear rounded-xl bg-blue-700 px-4 py-1 text-center text-base font-medium text-white transition duration-200 hover:!bg-blue-500 active:!bg-white/70"
          onClick={handleNextEntry}
          disabled={currentEntryIndex === entries.length - 1}
        >
          Next
        </button>
      </div>
      <div className="mt-4 w-full">
        <h4 className="mb-[14px] max-w-full text-xl font-bold  md:w-[64%] md:text-3xl md:leading-[42px] lg:w-[46%] xl:w-[85%] 2xl:w-[75%] 3xl:w-[52%]">
          Entry {currentEntryIndex + 1}
        </h4>
        <h4 className="md:text-1xl mb-[5px] max-w-full text-xl  font-bold md:w-[64%] md:leading-[42px] lg:w-[46%] xl:w-[85%] 2xl:w-[75%] 3xl:w-[52%]">
          User Basic Info:
        </h4>
        <p className=" max-w-full text-base font-medium  md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%] 3xl:w-[45%]">
          <span className="font-[700]">NAME:</span> {user?.firstname}{" "}
          {user?.lastname}
        </p>
        <p className="max-w-full text-base font-medium  md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%] 3xl:w-[45%]">
          <span className="font-[700]">EMAIL</span>: {user?.email}
        </p>
        <p className="max-w-full text-base font-medium  md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%] 3xl:w-[45%]">
          <span className="font-[700]">Date:</span>{" "}
          {new Date(user?.createdAt).toLocaleDateString()}
        </p>

        {/* <div className="mt-[16px] flex items-center justify-end gap-4 sm:justify-end 2xl:gap-10">
          <button className="linear rounded-xl bg-blue-700 px-4 py-1 text-center text-base font-medium text-white transition duration-200 hover:!bg-blue-500 active:!bg-white/70">
            View Profile
          </button>
        </div> */}
      </div>
      <hr className="my-4" />
      <div className="w-full">
        <h4 className="md:text-1xl mb-[5px] max-w-full text-xl  font-bold md:w-[64%] md:leading-[42px] lg:w-[46%] xl:w-[85%] 2xl:w-[75%] 3xl:w-[52%]">
          Registration Entry:
        </h4>
        <div className="grid gap-3">
          {Object.entries(currentEntry).map(([key, value]) => {
            if (
              !excludedKeys.includes(key) &&
              (typeof value === "string" || typeof value === "number")
            ) {
              const isEmpty = value === "";
              return (
                <div
                  key={key}
                  className={
                    isEmpty
                      ? "grid max-w-full gap-2 rounded-[10px] bg-[#ed9039]  p-4"
                      : "grid max-w-full gap-2 rounded-[10px] bg-[#e9ecef]  p-4"
                  }
                >
                  <p className=" flex items-center text-base text-[1.1rem] font-[700] uppercase">
                    <TbSquareRoundedLetterQ className="mr-3 inline" />
                    {formatKey(key)}
                  </p>
                  <p className="ml-4 rounded border border-[#fff] bg-white px-2 py-[.12rem] text-base font-medium">
                    {value ? value : "null"}
                  </p>
                </div>
              );
            }
            return null;
          })}
          {Object.entries(currentEntry).map(([key, value]) => {
            if (
              !excludedKeys.includes(key) &&
              typeof value === "object" &&
              value !== null &&
              value.url
            ) {
              const isEmpty = value === null;
              return (
                <div
                  key={key}
                  className={
                    isEmpty
                      ? "grid max-w-full gap-2 rounded-[10px] bg-[#ed9039]  p-4"
                      : "grid max-w-full gap-2 rounded-[10px] bg-[#e9ecef]  p-4"
                  }
                >
                  <p className=" flex items-center text-base text-[1.1rem] font-[700] uppercase">
                    <TbSquareRoundedLetterQ className="mr-3 inline" />
                    {formatKey(key)}
                  </p>
                  <p className="ml-4 rounded border border-[#fff] bg-white px-2 py-[.12rem] text-base font-medium">
                    <img src={value.url} alt={key} />
                  </p>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <button
          className="linear rounded-xl bg-blue-700 px-4 py-1 text-center text-base font-medium text-white transition duration-200 hover:!bg-blue-500 active:!bg-white/70"
          onClick={handlePreviousEntry}
          disabled={currentEntryIndex === 0}
        >
          Previous
        </button>
        <p>Entry {currentEntryIndex + 1} of {numberOfUsersEntry}</p>
        <button
          className="linear rounded-xl bg-blue-700 px-4 py-1 text-center text-base font-medium text-white transition duration-200 hover:!bg-blue-500 active:!bg-white/70"
          onClick={handleNextEntry}
          disabled={currentEntryIndex === entries.length - 1}
        >
          Next Entry {currentEntryIndex + 2}
        </button>
      </div>
    </div>
  );
};

export default ResultDisplay;
