import { MdEngineering } from "react-icons/md";
import { IoShieldCheckmark } from "react-icons/io5";
import { FaWpforms } from "react-icons/fa";

import Widget from "components/widget/Widget";

import { useEffect, useState } from "react";
import { getAllTuner } from "actions/tuners";
import { getAllWizardEntries } from "actions/tuners";
import { getAllUserEntries } from "actions/user";

const Dashboard = () => {
  const [tuners, setTuners] = useState(null);
  const [tunersEntry, setTunersEntry] = useState(null);
  const [usersEntry, setUsersEntry] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllTuner()
      .then((tuners) => {
        setTuners(tuners);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
    getAllWizardEntries()
      .then((entries) => {
        setTunersEntry(entries);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });

    getAllUserEntries()
      .then((entries) => {
        setUsersEntry(entries);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  }, []);

  const numberOfTuners = tuners ? tuners.length : 0;
  const numberOfTunersEntry = tunersEntry ? tunersEntry.length : 0;
  const numberOfUsersEntry = usersEntry ? usersEntry.length : 0;


  if(loading) {
    return (
      <div className="mt-14">Loading...</div>
    )
  }

  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdEngineering className="h-7 w-7" />}
          title={"All Tuners"}
          subtitle={numberOfTuners}
        />
        <Widget
          icon={<IoShieldCheckmark className="h-7 w-7" />}
          title={"Registered Tuners"}
          subtitle={numberOfTunersEntry}
        />
        <Widget
          icon={<FaWpforms className="h-6 w-6" />}
          title={"All User Entries"}
          subtitle={numberOfUsersEntry}
        />
        {/* <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Your Balance"}
          subtitle={"$1,000"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"New Tasks"}
          subtitle={"145"}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Total Projects"}
          subtitle={"$2433"}
        /> */}
      </div>

      {/* Charts */}

      {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div> */}

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}
        {/* <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div> */}

        {/* Traffic chart & Pie Chart */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div> */}

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2"> */}
        {/* <TaskCard /> */}
        {/* <div className="grid grid-cols-1 rounded-[20px]"> */}
        {/* <MiniCalendar /> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
