import ResultDisplay from "./components/ResultDisplay";
import { useEffect, useState } from "react";
import { getAllWizardEntries } from "actions/tuners";
import { getTuner } from "actions/tuners";

const TunersEntry = () => {
  const [entries, setEntries] = useState([]);
  const [currentEntryIndex, setCurrentEntryIndex] = useState(0);
  const [tuner, setTuner] = useState(null);

  useEffect(() => {
    // Fetch entries from the server
    getAllWizardEntries()
      .then((entries) => {
        setEntries(entries);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        // setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (entries.length > 0) {
      const currentEntry = entries[currentEntryIndex];
      const userId = currentEntry.user; // Assuming "user" is the key for the user value in the entry object
      console.log(userId);
      if (userId) {
        // Make getUser request
        getTuner(userId)
          .then((tuner) => {
            setTuner(tuner);
            console.log(tuner);
            // setLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
            // setLoading(false);
          });
      }
    }
  }, [entries, currentEntryIndex]);

  const handleNextEntry = () => {
    if (currentEntryIndex < entries.length - 1) {
      setCurrentEntryIndex(currentEntryIndex + 1);
    }
  };

  const handlePreviousEntry = () => {
    if (currentEntryIndex > 0) {
      setCurrentEntryIndex(currentEntryIndex - 1);
    }
  };

  const formatKey = (key) => {
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };
  const excludedKeys = ["updatedAt", "createdAt", "__v"];
  const currentEntry = entries[currentEntryIndex];

  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5">
      <div className="col-span-1 h-fit w-full">
        {entries.length > 0 ? (
          <div>
            <ResultDisplay
              entries={entries}
              currentEntryIndex={currentEntryIndex}
              currentEntry={currentEntry}
              formatKey={formatKey}
              excludedKeys={excludedKeys}
              tuner={tuner}
              handleNextEntry={handleNextEntry}
              handlePreviousEntry={handlePreviousEntry}
            />
            <button
              onClick={handlePreviousEntry}
              disabled={currentEntryIndex === 0}
            >
              Previous
            </button>
            <button
              onClick={handleNextEntry}
              disabled={currentEntryIndex === entries.length - 1}
            >
              Next
            </button>
          </div>
        ) : (
          <p className='dark:text-white'>No entries found</p>
        )}
       
      </div>
    </div>
  );
};

export default TunersEntry;
