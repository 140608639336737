import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { admin_server } from "servers";

const ProtectedRoute = ({ children, isSuccess, setIsSuccess }) => {
  const navigate = useNavigate();

  useEffect(() => {
    getUserAuth()
      .then(({ user, error }) => {
        if (user !== null && !error) {
          setIsSuccess(true);
          console.log('aaaaa');
          navigate("/admin");
          return;
        }
      })
      .catch((error) => {
        console.error(error);
        // navigate("/");
      });
  }, []);

  if (!isSuccess) {
    return (
      <div className="grid h-screen place-items-center">
        <div className="flex h-full w-full items-center justify-center">
          Loading...
        </div>
      </div>
    );
  }

  return <div>{children}</div>;
};

export default ProtectedRoute;

export function getUserAuth() {
  return axios
    .get(`${admin_server}/route-activation`, {
      withCredentials: true,
    })
    .then((response) => {
      return {
        user: response.data,
        error: null,
      };
    })
    .catch((error) => {
      const axiosError = error;
      return {
        user: null,
        error: axiosError,
      };
    });
}
