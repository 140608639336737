import { useNavigate } from "react-router-dom";
import { logout } from "../../../actions/admin";
import toast from "react-hot-toast";
const FreeCard = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout()
      .then(({ message, error }) => {
        if (message) {
          toast.success(message);
          navigate("/auth");
          window.location.reload(true);
          return;
        } else if (error) {
          toast("Failed to logout!");
        }
      })
      .catch((error) => {
        console.error(error);
        // navigate("/");
      });
  };
  return (
    <div className="relative mt-14 flex w-[256px] justify-center rounded-[20px] pb-4">
      <div className="mt-16 flex h-fit flex-col items-center">
        <button
          onClick={handleLogout}
          className="text-medium mt-7 block rounded-full border border-[blue] bg-gradient-to-b  py-[12px] px-11 text-center text-base  "
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default FreeCard;
