
import axios from "axios";
import { tuner_server } from "servers";

export const getAllTuner = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${tuner_server}/admin/get-tuners`, { withCredentials: true })
      .then((response) => {
        const tuners = response.data.tuners;
        console.log(tuners);
        resolve(tuners);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};
export const getAllWizardEntries = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${tuner_server}/admin/get-wizard-entries`, { withCredentials: true })
      .then((response) => {
        const entries = response.data.wizardData;
        console.log(entries);
        resolve(entries);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};
export const getTuner = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${tuner_server}/admin/get-tuner/${userId}`, { withCredentials: true })
      .then((response) => {
        const tuner = response.data.tuner;
        console.log(tuner);
        resolve(tuner);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err);
      });
  });
};